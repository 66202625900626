




import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Aplicaciones extends Vue {
  private mounted() {
    this.redireccionarSegunDispositivo();
  }

  private redireccionarSegunDispositivo() {
    var userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("android") !== -1) {
      //window.location.href = "market://details?id=cl.tarjetavecino.com.colina.colina_app";
      window.location.href =
        "https://play.google.com/store/apps/details?id=cl.tarjetavecino.com.colina.colina_app";
    } else if (
      userAgent.indexOf("iphone") !== -1 ||
      userAgent.indexOf("ipad") !== -1
    ) {
      window.location.href =
        "https://apps.apple.com/us/app/app-tarjeta-colina-vecino/id1435750215";
    } else {
      window.location.href = "https://tarjetavecino.colina.cl/";
    }
  }
}
